var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "fill-height": "" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { xl: "9" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c("v-card-title", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-center flex-column justify-center mt-15 mb-5",
                        staticStyle: { width: "100%" }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "primary--text display-1 text-center font-weight-bold primary--text text-uppercase"
                          },
                          [_vm._v("Privacy Policy")]
                        )
                      ]
                    )
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-divider", { staticClass: "mb-10" }),
                      _c("div", { class: _vm.policySectionClass }, [
                        _c("span", { class: _vm.mainHeadlineClass }, [
                          _vm._v(_vm._s(_vm.ourRoleInYourPolicy.mainHeadline))
                        ]),
                        _vm.ourRoleInYourPolicy.mainSubHeadline
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.ourRoleInYourPolicy.mainSubHeadline)
                              )
                            ])
                          : _vm._e(),
                        _c(
                          "span",
                          { staticClass: "font-weight-bold subtitle-1 mt-3" },
                          [
                            _vm._v(
                              _vm._s(_vm.ourRoleInYourPolicy.sections[0].title)
                            )
                          ]
                        ),
                        _c("span", { staticClass: "my-3" }, [
                          _vm._v(
                            _vm._s(_vm.ourRoleInYourPolicy.sections[0].text)
                          )
                        ]),
                        _c(
                          "span",
                          { staticClass: "font-weight-bold subtitle-1 my-3" },
                          [
                            _vm._v(
                              _vm._s(_vm.ourRoleInYourPolicy.sections[1].title)
                            )
                          ]
                        ),
                        _c(
                          "ul",
                          _vm._l(
                            _vm.ourRoleInYourPolicy.sections[1].listData,
                            function(item, index) {
                              return _c("li", { key: index }, [
                                _vm._v(_vm._s(item))
                              ])
                            }
                          ),
                          0
                        )
                      ]),
                      _c("v-divider", { staticClass: "my-10" }),
                      _c("div", { class: _vm.policySectionClass }, [
                        _c("span", { class: _vm.mainHeadlineClass }, [
                          _vm._v(
                            _vm._s(_vm.whenAndHowWeCollectData.mainHeadline)
                          )
                        ]),
                        _vm.whenAndHowWeCollectData.mainSubHeadline
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.whenAndHowWeCollectData.mainSubHeadline
                                )
                              )
                            ])
                          : _vm._e(),
                        _c("span", { staticClass: "font-weight-bold my-3" }, [
                          _vm._v(
                            _vm._s(
                              _vm.whenAndHowWeCollectData.sections[0].title
                            )
                          )
                        ]),
                        _c(
                          "ul",
                          _vm._l(
                            _vm.whenAndHowWeCollectData.sections[0].listData,
                            function(item, index) {
                              return _c("li", { key: index }, [
                                _vm._v(_vm._s(item))
                              ])
                            }
                          ),
                          0
                        )
                      ]),
                      _c("v-divider", { staticClass: "my-10" }),
                      _c(
                        "div",
                        { class: _vm.policySectionClass },
                        [
                          _c("span", { class: _vm.mainHeadlineClass }, [
                            _vm._v(
                              _vm._s(_vm.typesOfDataWeCollect.mainHeadline)
                            )
                          ]),
                          _vm._l(_vm.typesOfDataWeCollect.sections, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "d-flex flex-column" },
                              [
                                _c("span", { class: _vm.titleClass }, [
                                  _vm._v(_vm._s(item.title))
                                ]),
                                _c("span", { staticClass: "my-3" }, [
                                  _vm._v(_vm._s(item.text))
                                ])
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _c("v-divider", { staticClass: "my-10" }),
                      _c(
                        "div",
                        {
                          class: _vm.policySectionClass,
                          attrs: { id: "howWhyWeUseData" }
                        },
                        [
                          _c("span", { class: _vm.mainHeadlineClass }, [
                            _vm._v(
                              _vm._s(_vm.howAndWhyWeUseYourData.mainHeadline)
                            )
                          ]),
                          _vm._l(_vm.howAndWhyWeUseYourData.sections, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "d-flex flex-column" },
                              [
                                item.title
                                  ? _c("span", { class: _vm.titleClass }, [
                                      _vm._v(_vm._s(item.title))
                                    ])
                                  : _vm._e(),
                                item.subtitle
                                  ? _c("span", { class: _vm.subtitleClass }, [
                                      _vm._v(_vm._s(item.subtitle))
                                    ])
                                  : _vm._e(),
                                _c("span", {
                                  staticClass: "my-3",
                                  domProps: { innerHTML: _vm._s(item.text) }
                                }),
                                item.listData
                                  ? _c(
                                      "ul",
                                      _vm._l(item.listData, function(element) {
                                        return _c("li", { key: element }, [
                                          _vm._v(" " + _vm._s(element) + " ")
                                        ])
                                      }),
                                      0
                                    )
                                  : _vm._e()
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _c("v-divider", { staticClass: "my-10" }),
                      _c(
                        "div",
                        { class: _vm.policySectionClass },
                        [
                          _c("span", { class: _vm.mainHeadlineClass }, [
                            _vm._v(
                              _vm._s(
                                _vm.yourPrivacyChoicesAndRights.mainHeadline
                              )
                            )
                          ]),
                          _vm._l(
                            _vm.yourPrivacyChoicesAndRights.sections,
                            function(item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "d-flex flex-column"
                                },
                                [
                                  item.type && item.type === "mainSubtitle"
                                    ? _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.titleClass,
                                            "my-5",
                                            "text-uppercase"
                                          ]
                                        },
                                        [_vm._v(_vm._s(item.title))]
                                      )
                                    : _c("span", { class: _vm.titleClass }, [
                                        _vm._v(_vm._s(item.title))
                                      ]),
                                  _c("span", {
                                    staticClass: "my-3",
                                    domProps: { innerHTML: _vm._s(item.text) }
                                  }),
                                  item.listData
                                    ? _c(
                                        "ul",
                                        _vm._l(item.listData, function(
                                          element
                                        ) {
                                          return _c("li", { key: element }, [
                                            _vm._v(" " + _vm._s(element) + " ")
                                          ])
                                        }),
                                        0
                                      )
                                    : _vm._e()
                                ]
                              )
                            }
                          )
                        ],
                        2
                      ),
                      _c("v-divider", { staticClass: "my-10" }),
                      _c(
                        "div",
                        { class: _vm.policySectionClass },
                        [
                          _c("span", { class: _vm.mainHeadlineClass }, [
                            _vm._v(_vm._s(_vm.dataSecurity.mainHeadline))
                          ]),
                          _vm._l(_vm.dataSecurity.sections, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "d-flex flex-column" },
                              [
                                _c("span", { class: _vm.titleClass }, [
                                  _vm._v(_vm._s(item.title))
                                ]),
                                _c("span", {
                                  staticClass: "my-3",
                                  domProps: { innerHTML: _vm._s(item.text) }
                                }),
                                item.listData
                                  ? _c(
                                      "ul",
                                      _vm._l(item.listData, function(element) {
                                        return _c("li", {
                                          key: element,
                                          domProps: {
                                            innerHTML: _vm._s(element)
                                          }
                                        })
                                      }),
                                      0
                                    )
                                  : _vm._e()
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _c("v-divider", { staticClass: "my-10" }),
                      _c(
                        "div",
                        { class: _vm.policySectionClass },
                        [
                          _c("span", { class: _vm.mainHeadlineClass }, [
                            _vm._v(
                              _vm._s(_vm.whereDoWeStoreOurData.mainHeadline)
                            )
                          ]),
                          _vm._l(_vm.whereDoWeStoreOurData.sections, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "d-flex flex-column" },
                              [
                                item.title
                                  ? _c("span", { class: _vm.titleClass }, [
                                      _vm._v(_vm._s(item.title))
                                    ])
                                  : _vm._e(),
                                item.text
                                  ? _c("span", {
                                      staticClass: "my-3",
                                      domProps: { innerHTML: _vm._s(item.text) }
                                    })
                                  : _vm._e()
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _c("v-divider", { staticClass: "my-10" }),
                      _c(
                        "div",
                        { class: _vm.policySectionClass },
                        [
                          _c("span", { class: _vm.mainHeadlineClass }, [
                            _vm._v(
                              _vm._s(_vm.howLongDoWeStoreYourData.mainHeadline)
                            )
                          ]),
                          _vm._l(
                            _vm.howLongDoWeStoreYourData.sections,
                            function(item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "d-flex flex-column"
                                },
                                [
                                  item.title
                                    ? _c("span", { class: _vm.titleClass }, [
                                        _vm._v(_vm._s(item.title))
                                      ])
                                    : _vm._e(),
                                  item.text
                                    ? _c("span", {
                                        staticClass: "my-3",
                                        domProps: {
                                          innerHTML: _vm._s(item.text)
                                        }
                                      })
                                    : _vm._e()
                                ]
                              )
                            }
                          )
                        ],
                        2
                      ),
                      _c("v-divider", { staticClass: "my-10" }),
                      _c(
                        "div",
                        {
                          class: _vm.policySectionClass,
                          attrs: { id: "thirdPartiesAccess" }
                        },
                        [
                          _c("span", { class: _vm.mainHeadlineClass }, [
                            _vm._v(
                              _vm._s(
                                _vm.thirdPartiesWhoProcessData.mainHeadline
                              )
                            )
                          ]),
                          _vm._l(
                            _vm.thirdPartiesWhoProcessData.sections,
                            function(item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "d-flex flex-column"
                                },
                                [
                                  item.title
                                    ? _c("span", { class: _vm.titleClass }, [
                                        _vm._v(_vm._s(item.title))
                                      ])
                                    : _vm._e(),
                                  item.text
                                    ? _c("span", {
                                        staticClass: "my-3",
                                        domProps: {
                                          innerHTML: _vm._s(item.text)
                                        }
                                      })
                                    : _vm._e()
                                ]
                              )
                            }
                          ),
                          _vm._l(
                            _vm.thirdPartiesWhoProcessData.thirdPartiesData,
                            function(thirdPartyData) {
                              return _c(
                                "div",
                                {
                                  key: thirdPartyData.thirdPartyType,
                                  staticClass: "my-10"
                                },
                                [
                                  _c("v-data-table", {
                                    attrs: {
                                      headers: _vm.thirdPartiesHeaders,
                                      items: thirdPartyData.thirdPartyData,
                                      "hide-default-footer": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "top",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "mb-10" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "primary--text font-weight-bold subtitle-1 text-uppercase"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          thirdPartyData.thirdPartyType
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        },
                                        {
                                          key: "body",
                                          fn: function(ref) {
                                            var items = ref.items
                                            return [
                                              _c(
                                                "tbody",
                                                { staticClass: "mt-3" },
                                                _vm._l(items, function(
                                                  item,
                                                  index
                                                ) {
                                                  return _c(
                                                    "tr",
                                                    { key: index },
                                                    [
                                                      _c("td", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column align-start"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "subtitle-2 primary--text"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.provider
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "caption font-weight-bold",
                                                                attrs: {
                                                                  href:
                                                                    item.privacyLink,
                                                                  target:
                                                                    "_blank"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Privacy policy"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _c(
                                                          "ul",
                                                          {
                                                            staticClass: "py-3"
                                                          },
                                                          _vm._l(
                                                            item.collectedData,
                                                            function(
                                                              info,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "li",
                                                                { key: index },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        info
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(item.purpose)
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.processingPlace
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                }),
                                                0
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            }
                          )
                        ],
                        2
                      ),
                      _c("v-divider", { staticClass: "my-10" }),
                      _c(
                        "div",
                        { class: _vm.policySectionClass },
                        [
                          _c("span", { class: _vm.mainHeadlineClass }, [
                            _vm._v(_vm._s(_vm.cookies.mainHeadline))
                          ]),
                          _c("span", { class: _vm.mainSubHeadlineClass }, [
                            _vm._v(_vm._s(_vm.cookies.mainSubHeadline))
                          ]),
                          _vm._l(_vm.cookies.sections, function(item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "d-flex flex-column" },
                              [
                                item.title
                                  ? _c("span", { class: _vm.titleClass }, [
                                      _vm._v(_vm._s(item.title))
                                    ])
                                  : _vm._e(),
                                item.text
                                  ? _c("span", {
                                      staticClass: "my-3",
                                      domProps: { innerHTML: _vm._s(item.text) }
                                    })
                                  : _vm._e()
                              ]
                            )
                          }),
                          _vm._l(_vm.cookies.cookiesData, function(cookie) {
                            return _c(
                              "div",
                              {
                                key: cookie.cookieTypeHeadline,
                                staticClass: "my-10"
                              },
                              [
                                _c("v-data-table", {
                                  attrs: {
                                    headers: _vm.cookiesHeaders,
                                    items: cookie.cookieServiceData,
                                    "hide-default-footer": ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "top",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "mb-10" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "primary--text font-weight-bold subtitle-1 text-uppercase"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        cookie.cookieTypeHeadline
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      },
                                      {
                                        key: "body",
                                        fn: function(ref) {
                                          var items = ref.items
                                          return [
                                            _c(
                                              "tbody",
                                              { staticClass: "mt-3" },
                                              _vm._l(items, function(
                                                item,
                                                index
                                              ) {
                                                return _c(
                                                  "tr",
                                                  { key: index },
                                                  [
                                                    _c("td", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "subtitle-2 primary--text"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.provider
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    _c(
                                                      "td",
                                                      { staticClass: "py-1" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.purpose)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }),
                                              0
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          })
                        ],
                        2
                      ),
                      _c("v-divider", { staticClass: "my-10" }),
                      _c("div", [
                        _c("span", { class: _vm.mainHeadlineClass }, [
                          _vm._v("GÉANT Data Protection Code of Conduct")
                        ]),
                        _c(
                          "div",
                          { staticClass: "my-10" },
                          _vm._l(_vm.dataProtectionCodeOfConduct, function(
                            item,
                            index
                          ) {
                            return _c(
                              "v-row",
                              { key: index, attrs: { align: "center" } },
                              [
                                _c("v-col", { attrs: { cols: "3" } }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-bold primary--text"
                                    },
                                    [_vm._v(_vm._s(item.key))]
                                  )
                                ]),
                                _c("v-col", [
                                  item.referToExistingSection
                                    ? _c("div", [
                                        _vm._v(" Refer to the "),
                                        _c(
                                          "a",
                                          {
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.$vuetify.goTo(
                                                  "#" + item.sectionId
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(_vm._s(item.value))]
                                        ),
                                        _vm._v(
                                          " section of this privacy policy. "
                                        )
                                      ])
                                    : item.key !== "Personal data processed"
                                    ? _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(item.value)
                                        }
                                      })
                                    : _c("div", [
                                        _c(
                                          "ul",
                                          _vm._l(item.value, function(
                                            dataItem,
                                            idx
                                          ) {
                                            return _c("li", { key: idx }, [
                                              _c(
                                                "div",
                                                { staticClass: "d-flex" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(dataItem.text) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-weight-medium"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(dataItem.code)
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ]
                                              )
                                            ])
                                          }),
                                          0
                                        )
                                      ])
                                ])
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ]),
                      _c("v-divider", { staticClass: "my-10" }),
                      _c(
                        "div",
                        { class: _vm.policySectionClass },
                        [
                          _c("span", { class: _vm.mainHeadlineClass }, [
                            _vm._v(
                              _vm._s(_vm.makingThisPolicyGreat.mainHeadline)
                            )
                          ]),
                          _vm._l(_vm.makingThisPolicyGreat.sections, function(
                            section,
                            index
                          ) {
                            return _c("span", {
                              key: index,
                              staticClass: "my-3",
                              domProps: { innerHTML: _vm._s(section.text) }
                            })
                          })
                        ],
                        2
                      ),
                      _c("v-divider", { staticClass: "my-10" }),
                      _c(
                        "div",
                        {
                          class: _vm.policySectionClass,
                          attrs: { id: "contactUs" }
                        },
                        [
                          _c("span", { class: _vm.mainHeadlineClass }, [
                            _vm._v(_vm._s(_vm.contactUs.mainHeadline))
                          ]),
                          _vm.contactUs.mainSubHeadline
                            ? _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.contactUs.mainSubHeadline
                                  )
                                }
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column mt-5" },
                            _vm._l(_vm.contactUs.sections, function(section) {
                              return _c("span", { key: section.text }, [
                                _vm._v(_vm._s(section.text))
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }