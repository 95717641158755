<template>
    <v-container fill-height>
        <v-row justify="center">
            <v-col xl="9">
                <v-card flat>
                    <v-card-title>
                        <div style="width:100%" class="d-flex align-center flex-column justify-center mt-15 mb-5">
                            <span class="primary--text display-1 text-center font-weight-bold primary--text text-uppercase">Privacy Policy</span>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-divider class="mb-10" />
                        <div :class="policySectionClass">
                            <span :class="mainHeadlineClass">{{ ourRoleInYourPolicy.mainHeadline }}</span>
                            <span v-if="ourRoleInYourPolicy.mainSubHeadline">{{ ourRoleInYourPolicy.mainSubHeadline }}</span>
                            <span class="font-weight-bold subtitle-1 mt-3">{{ ourRoleInYourPolicy.sections[0].title }}</span>
                            <span class="my-3">{{ ourRoleInYourPolicy.sections[0].text }}</span>
                            <span class="font-weight-bold subtitle-1 my-3">{{ ourRoleInYourPolicy.sections[1].title }}</span>
                            <ul>
                                <li v-for="(item, index) in ourRoleInYourPolicy.sections[1].listData" :key="index">{{ item }}</li>
                            </ul>
                        </div>
                        <v-divider class="my-10" />
                        <div :class="policySectionClass">
                            <span :class="mainHeadlineClass">{{ whenAndHowWeCollectData.mainHeadline }}</span>
                            <span v-if="whenAndHowWeCollectData.mainSubHeadline">{{ whenAndHowWeCollectData.mainSubHeadline }}</span>
                            <span class="font-weight-bold my-3">{{ whenAndHowWeCollectData.sections[0].title }}</span>
                            <ul>
                                <li v-for="(item, index) in whenAndHowWeCollectData.sections[0].listData" :key="index">{{ item }}</li>
                            </ul>
                        </div>
                        <v-divider class="my-10" />
                        <div :class="policySectionClass">
                            <span :class="mainHeadlineClass">{{ typesOfDataWeCollect.mainHeadline }}</span>
                            <div v-for="(item, index) in typesOfDataWeCollect.sections" :key="index" class="d-flex flex-column">
                                <span :class="titleClass">{{ item.title }}</span>
                                <span class="my-3">{{ item.text }}</span>
                            </div>
                        </div>
                        <v-divider class="my-10" />
                        <div id="howWhyWeUseData" :class="policySectionClass">
                            <span :class="mainHeadlineClass">{{ howAndWhyWeUseYourData.mainHeadline }}</span>
                            <div v-for="(item, index) in howAndWhyWeUseYourData.sections" :key="index" class="d-flex flex-column">
                                <span v-if="item.title" :class="titleClass">{{ item.title }}</span>
                                <span v-if="item.subtitle" :class="subtitleClass">{{ item.subtitle }}</span>
                                <span class="my-3" v-html="item.text"></span>
                                <ul v-if="item.listData">
                                    <li v-for="element in item.listData" :key="element">
                                        {{ element }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <v-divider class="my-10" />
                        <div :class="policySectionClass">
                            <span :class="mainHeadlineClass">{{ yourPrivacyChoicesAndRights.mainHeadline }}</span>
                            <div v-for="(item, index) in yourPrivacyChoicesAndRights.sections" :key="index" class="d-flex flex-column">
                                <span v-if="item.type && item.type === 'mainSubtitle'" :class="[titleClass, 'my-5', 'text-uppercase']">{{ item.title }}</span>
                                <span v-else :class="titleClass">{{ item.title }}</span>
                                <span class="my-3" v-html="item.text"></span>
                                <ul v-if="item.listData">
                                    <li v-for="element in item.listData" :key="element">
                                        {{ element }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <v-divider class="my-10" />
                        <div :class="policySectionClass">
                            <span :class="mainHeadlineClass">{{ dataSecurity.mainHeadline }}</span>
                            <div v-for="(item, index) in dataSecurity.sections" :key="index" class="d-flex flex-column">
                                <span :class="titleClass">{{ item.title }}</span>
                                <span class="my-3" v-html="item.text"></span>
                                <ul v-if="item.listData">
                                    <li v-for="element in item.listData" :key="element" v-html="element"></li>
                                </ul>
                            </div>
                        </div>
                        <v-divider class="my-10" />
                        <div :class="policySectionClass">
                            <span :class="mainHeadlineClass">{{ whereDoWeStoreOurData.mainHeadline }}</span>
                            <div v-for="(item, index) in whereDoWeStoreOurData.sections" :key="index" class="d-flex flex-column">
                                <span v-if="item.title" :class="titleClass">{{ item.title }}</span>
                                <span v-if="item.text" class="my-3" v-html="item.text"></span>
                            </div>
                        </div>
                        <v-divider class="my-10" />
                        <div :class="policySectionClass">
                            <span :class="mainHeadlineClass">{{ howLongDoWeStoreYourData.mainHeadline }}</span>
                            <div v-for="(item, index) in howLongDoWeStoreYourData.sections" :key="index" class="d-flex flex-column">
                                <span v-if="item.title" :class="titleClass">{{ item.title }}</span>
                                <span v-if="item.text" class="my-3" v-html="item.text"></span>
                            </div>
                        </div>
                        <v-divider class="my-10" />
                        <div id="thirdPartiesAccess" :class="policySectionClass">
                            <span :class="mainHeadlineClass">{{ thirdPartiesWhoProcessData.mainHeadline }}</span>
                            <div v-for="(item, index) in thirdPartiesWhoProcessData.sections" :key="index" class="d-flex flex-column">
                                <span v-if="item.title" :class="titleClass">{{ item.title }}</span>
                                <span v-if="item.text" class="my-3" v-html="item.text"></span>
                            </div>
                            <div class="my-10" v-for="thirdPartyData in thirdPartiesWhoProcessData.thirdPartiesData" :key="thirdPartyData.thirdPartyType">
                                <v-data-table :headers="thirdPartiesHeaders" :items="thirdPartyData.thirdPartyData" hide-default-footer>
                                    <template v-slot:top>
                                        <div class="mb-10">
                                            <span class="primary--text font-weight-bold subtitle-1 text-uppercase">{{ thirdPartyData.thirdPartyType }}</span>
                                        </div>
                                    </template>
                                    <template v-slot:body="{ items }">
                                        <tbody class="mt-3">
                                            <tr v-for="(item, index) in items" :key="index">
                                                <td>
                                                    <div class="d-flex flex-column align-start">
                                                        <span class="subtitle-2 primary--text">{{ item.provider }}</span>
                                                        <a class="caption font-weight-bold" :href="item.privacyLink" target="_blank">Privacy policy</a>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ul class="py-3">
                                                        <li v-for="(info, index) in item.collectedData" :key="index">
                                                            {{ info }}
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td>{{ item.purpose }}</td>
                                                <td>{{ item.processingPlace }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-data-table>
                            </div>
                        </div>
                        <v-divider class="my-10" />
                        <div :class="policySectionClass">
                            <span :class="mainHeadlineClass">{{ cookies.mainHeadline }}</span>
                            <span :class="mainSubHeadlineClass">{{ cookies.mainSubHeadline }}</span>
                            <div v-for="(item, index) in cookies.sections" :key="index" class="d-flex flex-column">
                                <span v-if="item.title" :class="titleClass">{{ item.title }}</span>
                                <span v-if="item.text" class="my-3" v-html="item.text"></span>
                            </div>
                            <div class="my-10" v-for="cookie in cookies.cookiesData" :key="cookie.cookieTypeHeadline">
                                <v-data-table :headers="cookiesHeaders" :items="cookie.cookieServiceData" hide-default-footer>
                                    <template v-slot:top>
                                        <div class="mb-10">
                                            <span class="primary--text font-weight-bold subtitle-1 text-uppercase">{{ cookie.cookieTypeHeadline }}</span>
                                        </div>
                                    </template>
                                    <template v-slot:body="{ items }">
                                        <tbody class="mt-3">
                                            <tr v-for="(item, index) in items" :key="index">
                                                <td>
                                                    <span class="subtitle-2 primary--text">{{ item.provider }}</span>
                                                </td>
                                                <td class="py-1">{{ item.purpose }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-data-table>
                            </div>
                        </div>
                        <v-divider class="my-10" />

                        <div>
                            <span :class="mainHeadlineClass">GÉANT Data Protection Code of Conduct</span>
                            <div class="my-10">
                                <v-row v-for="(item, index) in dataProtectionCodeOfConduct" :key="index" align="center">
                                    <v-col cols="3"
                                        ><span class="font-weight-bold primary--text">{{ item.key }}</span></v-col
                                    >
                                    <v-col>
                                        <div v-if="item.referToExistingSection">
                                            Refer to the <a @click.prevent="$vuetify.goTo(`#${item.sectionId}`)">{{ item.value }}</a> section of this privacy
                                            policy.
                                        </div>
                                        <div v-else-if="item.key !== 'Personal data processed'" v-html="item.value"></div>
                                        <div v-else>
                                            <ul>
                                                <li v-for="(dataItem, idx) in item.value" :key="idx">
                                                    <div class="d-flex">
                                                        <span
                                                            >{{ dataItem.text }} <span class="font-weight-medium">{{ dataItem.code }}</span></span
                                                        >
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                        <v-divider class="my-10" />

                        <div :class="policySectionClass">
                            <span :class="mainHeadlineClass">{{ makingThisPolicyGreat.mainHeadline }}</span>
                            <span v-for="(section, index) in makingThisPolicyGreat.sections" :key="index" class="my-3" v-html="section.text"></span>
                        </div>
                        <v-divider class="my-10" />

                        <div id="contactUs" :class="policySectionClass">
                            <span :class="mainHeadlineClass">{{ contactUs.mainHeadline }}</span>
                            <span v-if="contactUs.mainSubHeadline" v-html="contactUs.mainSubHeadline"></span>
                            <div class="d-flex flex-column mt-5">
                                <span v-for="section in contactUs.sections" :key="section.text">{{ section.text }}</span>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            mainHeadlineClass: 'headline primary--text font-weight-bold mb-5',
            mainSubHeadlineClass: 'primary--text',
            policySectionClass: 'd-flex flex-column subtitle-2 my-10',
            titleClass: 'font-weight-bold subtitle-1',
            subtitleClass: 'font-weight-bold subtitle-2',
            dataProtectionCodeOfConduct: [
                { key: 'Name of service', value: 'Nuvolos.cloud' },
                {
                    key: 'Description of the service',
                    value:
                        'Nuvolos is a browser-based computation and data platform that provides a single, easy-to-use environment for research and education.'
                },
                { key: 'Data controller and contact person', referToExistingSection: true, value: 'Contact us', sectionId: 'contactUs' },
                { key: 'Jurisdiction', value: 'CH Switzerland' },
                {
                    key: 'Personal data processed',
                    value: [
                        { text: 'Affiliation', code: '(eduPersonAffiliation)' },
                        { text: 'E-mail', code: '(email)' },
                        { text: 'Scoped affiliation', code: '(eduPersonScopedAffiliation)' },
                        { text: 'Targeted ID/ Persistent ID', code: '(eduPersonTargetedID)' },
                        { text: 'Name', code: '(commonName, displayName, givenName, surname)' },
                        { text: 'eduPerson Unique ID', code: '(eduPersonUniqueId)' },
                        { text: 'Principal name', code: '(eduPersonPrincipalName)' },
                        { text: 'SCHAC Home Organization', code: '(schacHomeOrganization)' },
                        { text: 'SCHAC Home Organization Type', code: '(schacHomeOrganizationType)' }
                    ]
                },
                {
                    key: 'Purpose of the processing of personal data',
                    referToExistingSection: true,
                    value: 'How and why we use your data',
                    sectionId: 'howWhyWeUseData'
                },
                {
                    key: 'Third parties to whom personal data is disclosed',
                    referToExistingSection: true,
                    value: 'Third parties who process your data',
                    sectionId: 'thirdPartiesAccess'
                },
                {
                    key: 'How to access, rectify and delete the personal data',
                    value: "To rectify the data released by your Home Organisation, contact your Home Organisation's IT helpdesk."
                },
                {
                    key: 'Data retention',
                    value:
                        'Your personal information (name, contact details, affiliation) is stored for the duration of your use of the services provided and an additional 12 months after you cease using our services. We keep this data in order to deal with any queries that may arise following your use of our services.'
                },
                {
                    key: 'Data Protection Code of Conduct',
                    value:
                        'Your personal data will be protected according to the <a style="text-decoration:none" href="http://www.geant.net/uri/dataprotection-code-of-conduct/v1" target="_blank">Code of Conduct for Service Providers</a>, a common standard for the research and higher education sector to protect your privacy.'
                }
            ],
            ourRoleInYourPolicy: {
                mainHeadline: 'Our role in your privacy',
                mainSubHeadline: 'If you are a Nuvolos user or customer, or just visiting our website, this policy applies to you.',
                sections: [
                    {
                        title: 'Our responsibilities',
                        text:
                            'If you are a registered Nuvolos user or a visitor to our website we act as the data controller of personal data. This means that we determine how and why your data is processed.'
                    },
                    {
                        title: 'Your responsibilities',
                        listData: [
                            'Read this privacy policy.',
                            'If you are our customer, please also check the contracts between us: they may contain further details on how we collect and process your data.',
                            'If you provide us with personal information about other people, or if others give us your information, we will only use that information for the specific reason for which it was provided to us. By submitting the information, you confirm that you have the right to authorise us to process it on your behalf in accordance with this Privacy Policy.'
                        ]
                    }
                ]
            },
            makingThisPolicyGreat: {
                mainHeadline: 'Making this policy great',
                sections: [
                    {
                        title: '',
                        text:
                            'Well done for getting to the bottom of this policy. We have designed it to be as transparent, useful and engaging as possible and we would love to hear your feedback on how we can make it even better - please contact us at <a href="mailto:info@nuvolos.cloud">info@nuvolos.cloud</a>.'
                    },
                    {
                        title: '',
                        text:
                            "Please don't make this the last time you read it as we will post any changes on this page - significant changes will, of course, be notified to you by email."
                    }
                ]
            },
            whenAndHowWeCollectData: {
                mainHeadline: 'When and how we collect data',
                mainSubHeadline:
                    'From the first moment you interact with Nuvolos, we are collecting data. Sometimes you provide us with data, sometimes data about you is collected automatically.',
                sections: [
                    {
                        title: 'Here is when and how we do this',
                        listData: [
                            'You browse any page of our website',
                            'You use Nuvolos',
                            'You receive emails from us',
                            'You chat with us for customer support',
                            'You connect integrations',
                            'You opt-in to marketing messages'
                        ]
                    }
                ]
            },
            typesOfDataWeCollect: {
                mainHeadline: 'Types of data we collect',
                sections: [
                    { title: 'Contact details', text: 'Your name, email address and organisation affiliation (if provided).' },
                    {
                        title: 'Data that identifies you',
                        text:
                            'Your IP address, login information, browser type and version, time zone setting, browser plug-in types, geolocation information about where you might be, operating system and version, and other, similar technical information.'
                    },
                    {
                        title: 'Data on how you use Nuvolos',
                        text:
                            'Your URL clickstreams (the path you take through our site), products/services viewed, page response times, download errors, how long you stay on our pages, what you do on those pages, how often, and other actions, as well as other similar technical information.'
                    },
                    {
                        title: 'What about really sensitive data?',
                        text:
                            'We do not collect any "sensitive data" about you (like racial or ethnic origin, political opinions, religious/philosophical beliefs, trade union membership, genetic data, biometric data, health data, data about your sexual life or orientation, and offenses or alleged offenses) except when we have your specific consent, or when we have to comply with the law.'
                    },
                    {
                        title: "What about the children's data?",
                        text:
                            'Nuvolos is a business-to-customer service directed to and intended for use only by those who are 18 years of age or over. We do not target Nuvolos at children, and we do not knowingly collect any personal data from any person under 16 years of age.'
                    }
                ]
            },
            howAndWhyWeUseYourData: {
                mainHeadline: 'How and why we use your data',
                mainSubheadline:
                    'Data protection law means that we can only use your data for certain reasons and where we have a legal basis to do so. Here are the reasons for which we process your data:',
                sections: [
                    {
                        title: 'Keeping Nuvolos running',
                        text:
                            'Managing your requests (like inviting users, sending a contract to sign to a counterparty), login and authentication, remembering your settings, processing payments, hosting, and back-end infrastructure. Legal basis: contract, legitimate interests.'
                    },
                    {
                        title: 'Improving Nuvolos',
                        text:
                            'Testing features, interacting with feedback platforms and questionnaires, managing landing pages, heat mapping our site, traffic optimization, and data analysis and research, including profiling and the use of machine learning and other techniques over your data and in some cases using third parties to do this. Legal basis for this data usage: contract, legitimate interests.'
                    },
                    {
                        title: 'Customer support',
                        text:
                            'Notifying you of any changes to our service, solving issues via live chat support, or email including any bug fixing. Legal basis for this data usage: contract'
                    },
                    {
                        title: 'Marketing purposes (with your consent)',
                        text:
                            'Sending you emails and messages about new features, products and services, and content. Legal basis for this data usage: consent or contract'
                    },
                    { title: 'Here is what each of these "legal bases" means:', text: '' },
                    { title: 'Consent', text: 'You have given clear consent for you to process your personal data for a specific purpose.' },
                    {
                        subtitle: 'You can change your mind!',
                        text:
                            'If you have previously given consent to our processing your data you can freely withdraw such consent at any time. You can do this by emailing us at <a href="mailto:info@nuvolos.cloud">info@nuvolos.cloud</a>.'
                    },
                    {
                        title: '',
                        text:
                            'If you do withdraw your consent, and if we do not have another legal basis for processing your information, then we will stop processing your personal data. If we do have another legal basis for processing your information, then we may continue to do so subject to your legal rights.'
                    },
                    {
                        title: 'Contract',
                        text:
                            'Processing your data is necessary for a contract you have with us, or because we have asked you to take specific steps before entering into that contract.'
                    },
                    {
                        title: 'Legitimate interests',
                        text:
                            'Processing your data is necessary for our legitimate interests or the legitimate interests of a third party, provided those interests are not outweighed by your rights and interests. These legitimate interests are:',
                        listData: [
                            'Gaining insights from your behaviour on our website',
                            'Delivering, developing and improving the Nuvolos service',
                            'Enabling us to enhance, customise or modify our services and comms',
                            'Determining whether marketing campaigns are effective',
                            'Enhancing data security'
                        ]
                    },
                    {
                        title: '',
                        text: 'In each case, these legitimate interests are only valid if they are not outweighed by your rights and interests.'
                    }
                ]
            },
            yourPrivacyChoicesAndRights: {
                mainHeadline: 'Your privacy choices and rights',
                sections: [
                    { title: 'Your choices', text: '', type: 'mainSubtitle' },
                    {
                        title: 'You can choose not to provide us with personal data',
                        text:
                            'If you choose to do this, you can continue to use the website and browse its pages, but we will not be able to process transactions without personal data.'
                    },
                    {
                        title: 'You can turn off cookies in your browser by changing its settings',
                        text:
                            'You can block cookies by activating a setting on your browser allowing you to refuse cookies. You can also delete cookies through your browser settings. If you turn off cookies, you can continue to use the website and browse its pages, but Nuvolos and certain services will not work effectively.'
                    },
                    {
                        title: 'You can ask us not to use your data for marketing',
                        text:
                            'We will inform you (before collecting your data) if we intend to use your data for marketing and if third parties are involved. You can opt-out from marketing by emailing us at <a href="mailto:info@nuvolos.cloud">info@nuvolos.cloud</a>.'
                    },
                    {
                        title: 'Your rights',
                        type: 'mainSubtitle',
                        text: 'You can exercise your rights by sending us an email at <a href="mailto:info@nuvolos.cloud">info@nuvolos.cloud</a>.'
                    },
                    {
                        title: 'You have the right to access the information we hold about you',
                        text: 'This includes the right to ask us supplementary information about:',
                        listData: [
                            'The categories of data we are processing',
                            'The purposes of data processing',
                            'The categories of third parties to whom the data may be disclosed',
                            'How long the data will be stored (or the criteria used to determine that period)',
                            'Your other rights regarding our use of your data'
                        ]
                    },
                    {
                        title: '',
                        text:
                            "We will provide you with the information within one month of your request, unless doing so would adversely affect the rights and freedoms of others (e.g. another person's confidentiality or intellectual property rights). We willll tell you if we can not meet your request for that reason."
                    },
                    { title: 'You have the right to make us correct any inaccurate personal data about you', text: '' },
                    {
                        title: 'You can object to us using your data for profiling you or making automated decisions about you',
                        text:
                            'We may use your data to determine whether we should let you know information that might be relevant to you (for example, tailoring emails to you based on your behaviour). Otherwise, the only circumstances in which we will do this is to provide the Nuvolos service to you.'
                    },
                    {
                        title: 'You have the right to port your data to another service',
                        text:
                            'We will give you a copy of your data in CSV or JSON so that you can provide it to another service. If you ask us and it is technically possible, we will directly transfer the data to the other service for you. We will not do so to the extent that this involves disclosing data about any other individual.'
                    },
                    {
                        title: 'You have the right to be ‘forgotten’ by us',
                        text:
                            'You can do this by asking us to erase any personal data we hold about you if it is no longer necessary for us to hold the data for purposes of your use of Nuvolos.'
                    },
                    {
                        title: 'You have the right to lodge a complaint regarding our use of your data',
                        text:
                            'Please tell us first, so we have a chance to address your concerns. If we fail in this, you have a right to file a complaint regarding our data protection practices with a supervisory authority. Please see this <a href="https://edpb.europa.eu/about-edpb/board/members_en" target="_blank>directory</a> for contact details. If you are in Switzerland, please visit the <a href="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html" target="_blank>Federal Data Protection and Information Commissioner (FDPIC) site</a> for contact details.'
                    }
                ]
            },
            dataSecurity: {
                mainHeadline: 'Data security',
                sections: [
                    {
                        title: 'How secure is the data we collect?',
                        text: 'We have physical, electronic, and managerial procedures to safeguard and secure the information we collect.'
                    },
                    {
                        title: 'And please remember:',
                        text: '',
                        listData: [
                            'You provide personal data at your own risk: unfortunately, no data transmission is guaranteed to be 100% secure',
                            'You are responsible for your username and password: keep them secret and safe!',
                            'If you believe your privacy has been breached, please contact us immediately on <a href="mailto:info@nuvolos.cloud">info@nuvolos.cloud</a>'
                        ]
                    }
                ]
            },
            whereDoWeStoreOurData: {
                mainHeadline: 'Where do we store the data?',
                sections: [
                    {
                        title: '',
                        text:
                            'The personal data we collect is processed at our offices in Lugano and in any data processing facilities operated by the third parties identified below.'
                    },
                    {
                        title: '',
                        text:
                            'By submitting your personal data, you agree to this transfer, storing or processing by us. If we transfer or store your information outside the European Economic Area (EEA) in this way, we will take steps to ensure that your privacy rights continue to be protected as outlined in this Privacy Policy.'
                    }
                ]
            },
            howLongDoWeStoreYourData: {
                mainHeadline: 'How long do we store your data?',
                sections: [
                    {
                        title: '',
                        text:
                            'We retain personal data no longer than is necessary for the purposes for which it is processed unless applicable law requires storage for a longer period of time.'
                    }
                ]
            },
            thirdPartiesWhoProcessData: {
                mainHeadline: 'Third parties who process your data',
                sections: [
                    {
                        title: '',
                        text:
                            'Tech businesses often use third parties to help them host their application, communicate with customers, power their emails etc. We partner with third parties who we believe are the best in their field at what they do.'
                    },
                    {
                        title: '',
                        text:
                            'When we do this, sometimes it is necessary for us to share your data with them in order to get these services to work well. Your data is shared only when strictly necessary and according to the safeguards and good practices detailed in this Privacy Policy.'
                    },
                    {
                        title: '',
                        text:
                            'Here are the details of our main third-party service providers, and what data they collect or we share with them, where they store the data and why they need it:'
                    }
                ],
                thirdPartiesData: [
                    {
                        thirdPartyType: 'Infrastructure',
                        thirdPartyData: [
                            {
                                provider: 'Amazon Web Services, Inc.',
                                collectedData: ['Contact details', 'Data from your contracts', 'Data that identifies you'],
                                purpose:
                                    'This is a web hosting provider: we use it to store contracts and other data you generate by using the service securely in the cloud.',
                                privacyLink: 'https://aws.amazon.com/privacy/',
                                processingPlace: 'EU and US'
                            },
                            {
                                provider: '1&1 IONOS SE',
                                collectedData: ['Data that identifies you'],
                                purpose:
                                    'This is a web hosting provider: we use it to store contracts and other data you generate by using the service securely in the cloud.',
                                privacyLink: 'https://www.ionos.de/terms-gtc/terms-privacy',
                                processingPlace: ' - '
                            },
                            {
                                provider: 'Microsoft Corporation',
                                collectedData: ['Data that identifies you'],
                                purpose: 'Microsoft Azure Services',
                                privacyLink: 'https://privacy.microsoft.com/en-us/privacystatement',
                                processingPlace: 'EU and US'
                            }
                        ]
                    },
                    {
                        thirdPartyType: 'Analytics',
                        thirdPartyData: [
                            {
                                provider: 'Google Analytics',
                                collectedData: ['Contact details', 'Data on how you use Nuvolos', 'Data that identifies you', 'Cookies'],
                                purpose: 'This is a web analytics service: we use it to track your use of the service, and prepare reports on user activity.',
                                privacyLink: 'https://www.google.com/policies/privacy/',
                                processingPlace: 'US'
                            },
                            {
                                provider: 'Logrocket',
                                collectedData: ['Contact details', 'Data on how you use Nuvolos'],
                                purpose: 'This is a web analytics service: we use it to track your use of the service and prepare reports on user activity.',
                                privacyLink: 'https://logrocket.com/privacy/',
                                processingPlace: 'US'
                            }
                        ]
                    },
                    {
                        thirdPartyType: 'Integrations (by your request)',
                        thirdPartyData: [
                            {
                                provider: 'Auth0, Inc.',
                                collectedData: ['Data that identifies you', 'Data on how you use Nuvolos', 'Contact details', 'Cookies'],
                                purpose: 'To enable users to authenticate.',
                                privacyLink: 'https://auth0.com/privacy',
                                processingPlace: 'US'
                            },
                            {
                                provider: 'SWITCH',
                                collectedData: ['Data that identifies you', 'Data on how you use Nuvolos', 'Contact details', 'Cookies'],
                                purpose: 'To enable users to authenticate via their university login.',
                                privacyLink: 'https://www.switch.ch/aai/about/privacy/',
                                processingPlace: 'CH'
                            },
                            {
                                provider: 'eduGAIN interfederation service',
                                collectedData: ['Data that identifies you', 'Data on how you use Nuvolos', 'Contact details', 'Cookies'],
                                purpose: 'To enable users to authenticate via their university login.',
                                privacyLink: 'https://edugain.org/privacy/',
                                processingPlace: 'EU'
                            }
                        ]
                    },
                    {
                        thirdPartyType: 'Comms',
                        thirdPartyData: [
                            {
                                provider: 'The Rocket Science Group LLC',
                                collectedData: ['Contact details'],
                                purpose: 'We use this service for sending, storing and tracking emails.',
                                privacyLink: 'https://mailchimp.com/legal/privacy/',
                                processingPlace: 'US'
                            },
                            {
                                provider: 'Basecamp LLC',
                                collectedData: ['Contact details'],
                                purpose: 'We use this service for sending, storing and tracking emails.',
                                privacyLink: 'https://mailchimp.com/legal/privacy/',
                                processingPlace: 'US'
                            },
                            {
                                provider: 'Copper CRM, Inc.',
                                collectedData: ['Contact details'],
                                purpose: 'We use this service for sending, storing and tracking emails.',
                                privacyLink: 'https://www.copper.com/privacy',
                                processingPlace: 'US'
                            },
                            {
                                provider: 'Intercom (Intercom R&D Unlimited Company, a company registered in Ireland & Intercom, Inc., a Delaware corporation)',
                                collectedData: ['Contact details'],
                                purpose: 'We use this service to interact with you via a direct messaging platform integrated into Nuvolos.',
                                privacyLink: 'https://www.intercom.com/legal/privacy',
                                processingPlace: 'EU and US'
                            }
                        ]
                    },
                    {
                        thirdPartyType: 'Payments',
                        thirdPartyData: [
                            {
                                provider: 'Stripe, Inc.',
                                collectedData: ['Contact details', 'Financial information', 'Cookies'],
                                purpose: 'This service processes payments for us.',
                                processingPlace: 'EU and US',
                                privacyLink: 'https://stripe.com/privacy-shield-policy'
                            }
                        ]
                    }
                ]
            },
            cookies: {
                mainHeadline: 'Cookies',
                sections: [
                    {
                        title: '',
                        text:
                            'We use cookies. Unless you adjust your browser settings to refuse cookies, we (and these third parties) will issue cookies when you interact with Nuvolos. These may be "session" cookies, meaning they delete themselves when you leave Nuvolos, or "persistent" cookies which do not delete themselves and help us recognise you when you return so we can provide a tailored service.'
                    },
                    {
                        title: 'How can I block cookies?',
                        text:
                            'You can block cookies by activating a setting on your browser allowing you to refuse the setting of cookies. You can also delete cookies through your browser settings. If you use your browser settings to disable, reject, or block cookies (including essential cookies), certain parts of our website will not function fully. In some cases, our website may not be accessible at all. Please note that where third parties use cookies we have no control over how those third parties use those cookies.'
                    }
                ],
                cookiesData: [
                    {
                        cookieTypeHeadline: 'Which specific cookies do we use?',
                        cookieServiceData: [
                            {
                                provider: 'Google, Inc.',
                                purpose:
                                    'Google Analytics uses cookies to allow us to see information on the activities of visitors to our website and users of our service, including page views, source and time spent on Nuvolos. The information is depersonalized and is displayed as numbers, meaning it cannot be tracked back to individuals. This will help to protect your privacy.'
                            }
                        ]
                    }
                ]
            },
            contactUs: {
                mainHeadline: 'Contact us',
                mainSubHeadline:
                    'If you have any questions about this Privacy Statement, please contact us at <a href="mailto:info@nuvolos.cloud">info@nuvolos.cloud</a>, or by mail to:',
                sections: [
                    { text: 'The Data Protection Officer (DPO)' },
                    { text: 'Alphacruncher AG' },
                    { text: 'Via Coremmo 14a' },
                    { text: '6900 Lugano' },
                    { text: 'Switzerland' },
                    { text: 'Phone: ‭+41 44 585 27 76‬' }
                ],
                cookiesData: [
                    {
                        cookieTypeHeadline: 'Which specific cookies do we use?',
                        cookieServiceData: [
                            {
                                provider: 'Google, Inc.',
                                purpose:
                                    'Google Analytics uses cookies to allow us to see information on the activities of visitors to our website and users of our service, including page views, source and time spent on Nuvolos. The information is depersonalized and is displayed as numbers, meaning it cannot be tracked back to individuals. This will help to protect your privacy.'
                            }
                        ]
                    }
                ]
            },
            thirdPartiesHeaders: [
                { text: 'Service Provider', align: 'left', value: 'provider', width: '20%' },
                { text: 'Collected/shared data', value: 'collectedData', align: 'left', width: '25%' },
                {
                    text: 'Purpose',
                    value: 'purpose',
                    align: 'left',
                    width: '40%'
                },
                { text: 'Place of processing', value: 'processingPlace', align: 'left', width: '15%' }
            ],
            cookiesHeaders: [
                { text: 'Service Provider', align: 'left', value: 'provider', width: '20%' },
                {
                    text: 'Purpose',
                    value: 'purpose',
                    align: 'left',
                    width: '80%'
                }
            ]
        }
    },
    mounted() {
        window.scrollTo(0, 0)
    }
}
</script>
